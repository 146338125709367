<template>
    <div class="container" id="content">
      <div class="title">
        <div class="title-top">
          <div class="el-title-item">
            <el-form ref="form" label-width="80px">
              <el-form-item label="">
                <el-button type="success" size="small" @click="addOption">新增</el-button>
              </el-form-item>
              <el-form-item label="" style="margin-left: 2vw">
                <el-input v-model="searchName" placeholder="请输入批次号"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
        </div>
      </div>
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; margin-top: 1vh;">
        <el-table-column show-overflow-tooltip label="序号" align="center" :index="indexMethod" width="100"
        type="index"></el-table-column>
        <el-table-column prop="batchNumber" label="批次号" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="cropName" label="作物名称" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="cropType" label="作物类型" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="baseName" label="基地名称" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="principalName" label="负责人" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="area" label="种植面积" show-overflow-tooltip align="center"></el-table-column>
        <el-table-column prop="plantBatchState" label="是否结束" show-overflow-tooltip align="center">
            <template slot-scope="scope">
                <span v-if="scope.row.plantBatchState == 0">否</span>
                <span v-if="scope.row.plantBatchState == 1">是</span>
            </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-edit"
              @click="edits(scope.row)"
            ></el-button>
            <el-button
              type="primary"
              size="small"
              icon="el-icon-delete"
              @click="deletes(scope.row.id)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block pagingBox">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
      <!--    编辑-->
      <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
        <div slot="title" class="header-title" style="position: relative">
          <span class="title-name"></span>
          <span class="title-age">{{ formTitle }}</span>
          <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
        </div>
        <div class="addAlaForm">
          <el-form ref="addForm" :rules="rules" :model="addForm" label-width="11vw">
            <el-form-item label="种植作物" prop="cropName">
                <el-select value-key="id" v-model="cropsSet" placeholder="请选择种植作物" @change="cropChange">
                    <el-option v-for="item in cropsList" :key="item.id" :label="item.cropName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属基地" prop="baseName">
                <el-select value-key="id" v-model="baseSet" placeholder="请选择基地" @change="baseChange">
                    <el-option v-for="item in baseList" :key="item.id" :label="item.baseName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="负责人" prop="principalName">
              <el-input v-model="addForm.principalName" placeholder="请输入负责人名称"></el-input>
            </el-form-item>
            <el-form-item label="种植面积" prop="area">
              <el-input v-model="addForm.area" placeholder="请输入种植面积"></el-input>
            </el-form-item>
            <el-form-item label="种植时间" prop="plantTime">
              <el-date-picker
                  :disabled="batchInfo"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  v-model="addForm.plantTime"
                  type="datetime"
                  placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            
          </el-form>
          <div slot="footer" class="dialog-footer" v-show="showFlag">
            <el-button type="info" @click="messageBox = false">取消</el-button>
            <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
  <script>
  export default {
    // name: 'sensorManagement',
    data() {
      return {
        batchInfo: false,
        tableData: [],
        page: 0,
        size: 10,
        total: 0,
        loading: false,
        ids: 0,
        searchName: '',
        addForm: {},
        rules: {
          cropName: [
            {required: true,  trigger: ["blur", "change"], 
                validator:  (rule, value, callback)=> {
                    if (this.cropsSet == '') {
                        callback(new Error("种植作物不可为空"));
                    } else {
                        callback();
                    }
                }
            }],
          baseName: [
            {required: true,  trigger: ["blur", "change"], 
                validator:  (rule, value, callback)=> {
                    if (this.baseSet == '') {
                        callback(new Error("所属基地不可为空"));
                    } else {
                        callback();
                    }
                }
            }
          ],
          principalName: [
            {required: false, message: "负责人不可为空", trigger: ["blur", "change"]},
          ],
          area: [
            {required: false, message: "种植面积不可为空", trigger: ["blur", "change"]},
          ],
          plantTime: [
            {required: true, message: "种植时间不可为空", trigger: ["blur", "change"]},
          ],

        },
        formTitle: "",
        messageBox: false,
        showFlag: false,
        // value: true,
        pageSize: 10,
        pageNow: 1,
        cropsSet: '',
        cropsList: [],
        baseSet: '',
        baseList: [],
      };
    },
    mounted() {
      this.getList();
      this.getBaseData();
      this.getPlantData();
    },
    methods: {
        // 序号
        indexMethod(index) {
        return index + this.pageSize * (this.pageNow - 1) + 1;
        },
      //请求列表数据
      getList() {
        this.$get("/plantingBatch/getPlantingBatchList", {
          page: this.page,
          size: this.size,
          batchNumber: this.searchName, //批次号
        }).then((res) => {
          if (res.data.state == "success") {
            this.tableData = res.data.datas;
            this.total = res.data.count;
            //console.log("获取列表", this.tableData)
          }
        });
      },

    //  获取生长周期数据
      getPlantData(){
        this.$get('/lifeCycles/getLifeCycleList', { 
            page: 1, 
            size: 10000, 
        }).then((res) => {
        if (res.data.state == 'success') {
          this.cropsList = res.data.datas;
        }
      });
      },
    // 种植作物切换
    cropChange(val){
        //console.log(val);
        for (let i = 0; i < this.cropsList.length; i++) {
            if (this.cropsList[i].id == val) {
                this.addForm.cropId = this.cropsList[i].cropId;
                this.addForm.cropName = this.cropsList[i].cropName;
                this.addForm.cropType = this.cropsList[i].cropType;
                this.addForm.lifeCycleId = this.cropsList[i].id;
            }
        }
    },
    //  获取基地数据
    getBaseData(){
        this.$get('/baseManagement/getBaseManagements', { 
            page: 1, 
            size: 10000, 
        }).then((res) => {
        if (res.data.state == 'success') {
          this.baseList = res.data.datas;
        }
      });
      },
    // 基地切换
    baseChange(val){
        //console.log(val);
        for (let i = 0; i < this.baseList.length; i++) {
            if (this.baseList[i].id == val) {
                this.addForm.baseId = this.baseList[i].id;
                this.addForm.baseName = this.baseList[i].baseName;
            }
        }
    },

      // 编辑
      edits(row) {
        //console.log(row);
        this.cropsSet = row.cropId;
        this.baseSet = row.baseId;
        this.showFlag = true;
        this.ids = row.id;
        this.formTitle = "编辑";
        this.messageBox = true;
        this.addForm = JSON.parse(JSON.stringify(row));
      },
      // 删除
      deletes(id) {
        // 弹出提示是否删除
        this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$delete(`/cropManagement/delCropById/${id}`).then((res) => {
              if (res.data.state == "success") {
                this.$message.success("删除成功");
                this.getList();
              } else {
                this.$message.error("删除失败");
              }
            });
          })
          .catch((e) => e);
      },
      // 确认提交
      saveForm(formName) {
        // let id = this.ids;
        this.addForm.id = this.ids;
        // this.addForm.createUser = window.localStorage.getItem('user')
        //console.log(this.addForm);
        var url;
        if(this.formTitle == '新增'){
          delete this.addForm.id;
          url = '/plantingBatch/addPlantingBatch';
        }else{
          url='/plantingBatch/updatePlantingBatch';
        }
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$postJSON(url, this.addForm
            ).then((res) => {
              if (res.data.state == 'success') {
                if(this.formTitle == '新增'){
                  this.$message.success('添加成功')
                }else{
                  this.$message.success('修改成功')
                }    
                this.messageBox = false
                this.getList();
              } else {
                this.$message.error(res.data.msg)
                this.messageBox = false
              }
            })
          } else {
            // //console.log('error submit!!');
            return false;
          }
        });
      },
      // 新增
      addOption() {
        this.formTitle = "新增";
        this.cropsSet = '';
        this.baseSet = '';
        this.messageBox = true;
        this.showFlag = true;
        this.$nextTick(() => {
          this.addForm = {};
          this.$refs.addForm.resetFields();
        });
      },
      // 搜索
      search() {
        this.page = 1
        this.getList();
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.page = val;
        this.getList();
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  @import "../../../style/backTable.less";
  </style>
  <style scoped>
  #content /deep/ .el-table th {
    background-color: rgba(240, 242, 245, 1) !important;
  }
  
  #content /deep/ .el-table {
    max-height: 78vh;
    overflow-y: scroll;
  }
  
  #content /deep/ .el-table::-webkit-scrollbar {
    display: none;
  }
  
  .addAlaForm /deep/ .el-select {
    width: 100% !important;
  }
  
  .dialog-footer {
    height: 5vh;
    text-align: right;
  }
  
  .dialog-footer /deep/ .el-button, .el-button--info {
    margin-left: 10px !important;
  
  }
  
  .dialog-footer /deep/ .el-button--info {
    background-color: transparent !important;
    color: #000;
  }
  
  .title /deep/ .el-input__inner {
    height: 33px !important;
  }
  
  .drawerBox /deep/ .msg {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1vh 1vw;
  }
  
  </style>
  
  
  